var pipingnMixin = {
    methods: {
        answerToText: function (answer) {
            switch (answer.type) {
                case 'number':
                    return answer.number
                case 'phone_number':
                    return answer.phone_number
                case 'date':
                    return answer.date
                case 'boolean':
                    if (answer.boolean) {
                        return 'Tak'
                    } else {
                        return 'Nie'
                    }
                case 'text':
                    return answer.text
                case 'choices':
                    let str = ''
                    for (let i = 0; i < answer.choices.length; i++) {
                        if (str.length > 0) {
                            str = str + ', '
                        }
                        str = str + answer.choices[i].label
                    }
                    return str
            }
        },
        applyVariables: function (string) {

            if (!string) {
                return ''
            }
            let self = this
            if (string) {

                // components piping
                string = string.replace(/\{\{component:(.*?)\}\}/gi, function(match, token) {
                    if (self.store.answers.hasOwnProperty(token)) {
                        let answer = self.store.answers[token]
                        return self.answerToText(answer)
                    } else {
                        return ''
                    }
                })


                // data compare
                string = string.replace(/\{\{compare:(.*?)\}\}/gi, function(match, token2) {
                    var temp = token2.split("|")
                    var token = temp[0]
                    token = token.replace(/\s+/g, '')
                    if (self.store.answers.hasOwnProperty(token)) {
                        
                        let answer = self.store.answers[token]
                        let value = self.answerToText(answer)

                        temp[1] = temp[1].replace(/&#039;/g, "'")
                        temp[1] = temp[1].replace(/["']+/g, '')

                        if (value == temp[1]) {
                            return '<span class="quiz-compare"><i class="icon-yes"></i> ' + temp[1] + '</span>'
                        } else {
                            return '<span class="quiz-compare"><i class="icon-close"></i> <strike>' + value + '</strike> <i class="icon-right"></i>' + temp[1] + '</span>'
                        }
                    } else {
                        return ''
                    }
                })

                // hidden piping
                string = string.replace(/\{\{hidden:(.*?)\}\}/gi, function(match, token) {
                    if (self.store.hidden.hasOwnProperty(token)) {
                        return self.store.hidden[token].value
                    } else {
                        return ''
                    }
                })

                // score piping
                string = string.replace(/\{\{variable:(.*?)\}\}/gi, function(match, token) {
                    if (self.store.variables.hasOwnProperty(token)) {
                        return self.store.variables[token]
                    } else {
                        return ''
                    }
                })


                string = string.replace(/(?:\r\n|\r|\n)/g, '<br>')
            }


            // markdown
            let rules = [
                {regex: /!\[([^\[]+)\]\(([^\)]+)\)/g, replacement: '<img src=\'$2\' alt=\'$1\'>'},
                {regex: /\[([^\[]+)\]\(([^\)]+)\)/g, replacement: '<a href=\'$2\' target="_blank" rel="nofollow noreferrer noopener">$1</a>'},
                {regex: /(\*\*)(.*?)\1/g, replacement: '<strong>$2</strong>'},
                {regex: /(^|[\s])\*(.*?)\*(?=[\s]|$)/g, replacement: '$1<em>$2</em>'},
                {regex: /\~\~(.*?)\~\~/g, replacement: '<del>$1</del>'},
                {regex: /\\/g, replacement: ''},
            ];
            function markdown(text) {
                text = `\n${text}\n`;
                rules.forEach( (rule) => {
                    text = text.replace(rule.regex, rule.replacement);
                });
        
                return text.trim();
            }
            string = markdown(string)


            return string
        }
    }
}
export default pipingnMixin
